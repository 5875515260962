export const STAND_ALONE_RESULT_DELETE = {
  TITLE: 'Are you sure?',
  SUB_TEXT: 'Are you sure you want to delete the ##nameSingle## “##ID##”? All of the following related items will be deleted:',
  SUMMARY: 'Summary',
  OBJECTS: 'Object',

  CONFORM: "Yes, I'm sure",
  BACK: {
    LABEL: 'No, take me back',
    URL: '/',
  },
};
