import React from 'react';
import { fonts } from '../../styles/theme';
import InputCheckbox from '../InputCheckbox/Index';
import { LinkComponent } from '../Link';
import { TextP } from '../Text';
import * as Styled from './styles';
import Button from '../Button';

type FileInputProps = {
  label: string;
  useSubLabel?: boolean
  placeholder?: string;
  handleChange: (value: File, field: string) => void
  handleCheck?: (value: boolean) => void
  isChecked?: boolean
  field: string
  fileName?: string
  fileType?: string
  disabled?: boolean
  fileLink?: string
  getSignedUrl?: (field: string) => void
  error?: string
}
export const FileInput = ({
  label,
  placeholder,
  fileName,
  handleChange,
  field,
  disabled,
  useSubLabel = true,
  fileLink = '',
  fileType = 'application/pdf',
  error,
  handleCheck,
  isChecked,
  getSignedUrl,
}: FileInputProps) => {
  const handleChangeFile = ({ target: { files } }: any) => {
    handleChange(files[0], field);
  };

  return (
    <Styled.TextinputWrapper>
      <Styled.LabelStyled htmlFor={`id-${label}`}>
        <Styled.TextWrapper>
          {label}
        </Styled.TextWrapper>
      </Styled.LabelStyled>

      <Styled.FieldWrapper>
        {error && (
          <Styled.ErrorWrapper>
            <TextP>{error}</TextP>
          </Styled.ErrorWrapper>
        )}
        {fileName && fileName !== 'null' && (
          <Styled.InputWrapper>
            {useSubLabel && (
              <Styled.SubTextWraper>
                <TextP font={fonts.font1.H11}>Currently:</TextP>
              </Styled.SubTextWraper>
            )}
            {!getSignedUrl && (
              <LinkComponent url={fileLink}>
                <Styled.ButtonLableStyled>{fileName}</Styled.ButtonLableStyled>
              </LinkComponent>
            )}
            {getSignedUrl && (
              <Button
                onClick={() => getSignedUrl(field)}
                theme="plainFouth"
              >
                {fileName}
              </Button>
            )}
          </Styled.InputWrapper>
        )}

        <Styled.InputWrapper>
          {useSubLabel && (
            <Styled.SubTextWraper>
              <TextP font={fonts.font1.H11}>Change:</TextP>
            </Styled.SubTextWraper>
          )}
          <input
            type="file"
            accept={fileType}
            id={`id-${label}`}
            placeholder={placeholder}
            onChange={handleChangeFile}
            autoComplete="off"
            disabled={disabled}
          />
        </Styled.InputWrapper>
        {handleCheck && fileName !== 'null' && (
          <InputCheckbox
            label="Delete file"
            handleChange={handleCheck}
            checkedValue={isChecked || false}
            theme="secondary"
          />
        )}
      </Styled.FieldWrapper>
    </Styled.TextinputWrapper>
  );
};
