import React from 'react';
import { TextP } from '../Text';
import * as Styled from './styles';

type TextAreaProps = {
  label?: string;
  placeholder?: string;
  textValue: string,
  handleHandle: (textValue: string, keyName: string) => void
  keyName: string
  isDisabled?: boolean
  maxLenght?: number
  id?: string
  error?: string
}

export const TextArea = ({
  label, placeholder, textValue, handleHandle, isDisabled, id, keyName, error,
  maxLenght,
}: TextAreaProps) => (
  <Styled.TextAreaWrapper id={id}>
    <Styled.LabelStyled htmlFor={`id-${label}`}>
      {label}
    </Styled.LabelStyled>
    <Styled.TextInputWrapper>
      {error && (
        <Styled.ErrorWrapper>
          <TextP>{error}</TextP>
        </Styled.ErrorWrapper>
      )}
      <Styled.TextAreaStyled
        id={`id-${label}`}
        rows={10}
        placeholder={placeholder}
        autoComplete="off"
        value={textValue === 'null' ? '' : textValue}
        onChange={(event) => {
          handleHandle(event.target.value, keyName);
        }}
        maxLength={maxLenght}
        disabled={isDisabled}
        isError={!!error}
      />
    </Styled.TextInputWrapper>
  </Styled.TextAreaWrapper>
);
