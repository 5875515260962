/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { breachcrumbPath } from '../utils/breadcrumbConst';
import { createToastMessage } from '../utils/createToastMessage';
import { ppoResultschema } from '../utils/validations';
import { useApi } from './useApi';
import { STAND_ALONE_RESULTS_FORM } from '../pages/StandAloneResult/Edit/const';
import { updateStandeAloneResultValidations } from '../pages/StandAloneResult/Edit/validation';

export const useStandAloneResult = () => {
  const navigate = useNavigate();
  const {
    getOne,
    getIndex,
    getManyById,
    updateData,
    deleteMany,
  } = useApi();

  const baseUrl = 'core/standAloneResult';
  const controllerName = 'admin/resultStandAlone';
  const name = 'stand alone results';
  const nameSingle = 'stand alone result';

  const [standAloneResults, setStandAloneResults] = useState<IStandAloneResultIndex[] | []>([]);
  const [filterStandAloneResults, setFilterStandAloneResults] = useState<IStandAloneResultIndex[] | []>([]);
  const [deleteResultIds, setDeleteResultIds] = useState<{ id: number, title: string }[]>([]);

  const [standAloneResult, setStandAloneResult] = useState<IStandAloneResult>();
  const [saveName, setSavedName] = useState('');

  // OPTIONS
  const [researcherList, setResearcherList] = useState<{ label: string, value: number }[]>([]);
  const [healthCategories, setHealthCategories] = useState<{ label: string, value: number }[]>([]);
  const [healthAuthorityRegions, setHealthAuthorityRegions] = useState<{ label: string, value: number }[]>([]);
  const [hospitals, setHospitals] = useState<{ label: string, value: number }[]>([]);

  const [selectedStandAloneResults, setSelectedStandAloneResults] = useState<number[]>([]);
  const [changedFields, setChangedField] = useState<string[]>([]);
  const [originalStatus, setOriginalStatus] = useState<string>('');

  const [pageType, setPageType] = useState('change');
  const [toastMessage, setToastMessage] = useState<IToastMessage>();
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const [selectedValue, setSelectedValue] = useState('');

  const [resultStatusFilter, setResultStatusFilter] = useState('');
  const [searchFilter, setSearchFilter] = useState('');

  const [resourceFile, setResourceFile] = useState<File>();
  const [resourceFileDelete, setResourceFileDelete] = useState(false);

  const [history, setHistory] = useState<IHistoryLog[]>([]);

  const getBreadcrumbs = () => {
    const crumbs = [
      breachcrumbPath.core,
      breachcrumbPath.standAloneResult,
    ];

    if (standAloneResults.length === 1) {
      crumbs.push({
        url: `${breachcrumbPath.standAloneResult.url}/${standAloneResults[0].id}/change`,
        name: `${standAloneResults[0].id}` || '',
      });
      crumbs.push({ url: '', name: 'Delete' });
    } else {
      crumbs.push({ url: '', name: 'Delete multiple objects' });
    }
    return crumbs;
  };

  const handleChange = (value: string | number | boolean, field: string) => {
    setStandAloneResult((prev) => ({
      ...prev,
      [field]: value,
    }));

    if (!changedFields.includes(field)) {
      setChangedField((oldArray) => [...oldArray, field]);
    }
  };

  const handleCheck = (id: number) => {
    if (selectedStandAloneResults.includes(id)) {
      setSelectedStandAloneResults((oldArray) => oldArray.filter((arr) => id !== arr));
    } else {
      setSelectedStandAloneResults((oldArray) => [...oldArray, id]);
    }
  };

  const addRemoveListMembers = (ids: number[], add: boolean, keyName: string) => {
    if (!standAloneResult) return;
    if (keyName === STAND_ALONE_RESULTS_FORM.HEALTH_CATEGORIES.NAME) {
      setStandAloneResult((prev) => {
        if (add) {
          const newCategories = healthCategories?.filter((region) => ids.includes(region.value)) || [];
          if (newCategories.length === 0) return prev;

          return {
            ...prev,
            health_categories: [...(prev?.health_categories || []), ...newCategories],
          };
        }

        return {
          ...prev,
          health_categories: prev?.health_categories?.filter((category) => !ids.includes(category.value)) || [],
        };
      });
    }

    if (keyName === STAND_ALONE_RESULTS_FORM.REGIONS.NAME) {
      setStandAloneResult((prev) => {
        if (add) {
          const newRegions = healthAuthorityRegions?.filter((region) => ids.includes(region.value)) || [];
          if (newRegions.length === 0) return prev;

          return {
            ...prev,
            health_authority_region: [...(prev?.health_authority_region || []), ...newRegions],
          };
        }

        return {
          ...prev,
          health_authority_region: prev?.health_authority_region?.filter((region) => !ids.includes(region.value)) || [],
        };
      });
    }

    if (keyName === STAND_ALONE_RESULTS_FORM.TEAM_MEMBER.NAME) {
      setStandAloneResult((prev) => {
        if (add) {
          const newMembers = researcherList?.filter((member) => ids.includes(member.value)) || [];
          if (newMembers.length === 0) return prev;

          return {
            ...prev,
            team_members: [...(prev?.team_members || []), ...newMembers],
          };
        }

        return {
          ...prev,
          team_members: prev?.team_members?.filter((member) => !ids.includes(member.value)) || [],
        };
      });
    }

    if (!changedFields.includes(keyName)) {
      setChangedField((oldArray) => [...oldArray, keyName]);
    }
  };

  const addRemoveAllListMembers = (add: boolean, keyName: string) => {
    if (!standAloneResult) return;
    if (keyName === STAND_ALONE_RESULTS_FORM.HEALTH_CATEGORIES.NAME) {
      setStandAloneResult((prev) => {
        if (add) {
          return {
            ...prev,
            health_categories: healthCategories,
          };
        }

        return {
          ...prev,
          health_categories: [],
        };
      });
    }

    if (keyName === STAND_ALONE_RESULTS_FORM.REGIONS.NAME) {
      setStandAloneResult((prev) => {
        if (add) {
          return {
            ...prev,
            health_authority_region: healthAuthorityRegions,
          };
        }

        return {
          ...prev,
          health_authority_region: [],
        };
      });
    }

    if (keyName === STAND_ALONE_RESULTS_FORM.TEAM_MEMBER.NAME) {
      setStandAloneResult((prev) => {
        if (add) {
          return {
            ...prev,
            team_members: researcherList,
          };
        }

        return {
          ...prev,
          team_members: [],
        };
      });
    }

    if (!changedFields.includes(keyName)) {
      setChangedField((oldArray) => [...oldArray, keyName]);
    }
  };

  const handleCheckArray = (checked: boolean, value: string, keyName: string) => {
    let currentArray: string[] = [];
    switch (keyName) {
      case STAND_ALONE_RESULTS_FORM.BC_SUPPORT_UNIT.NAME:
        currentArray = standAloneResult?.regional_centers || [];
        break;
      default:
        break;
    }

    if (!currentArray) return;
    if (checked) {
      currentArray.push(value);
    } else {
      currentArray = currentArray.filter((item) => item !== value);
    }
    setStandAloneResult({
      ...standAloneResult,
      [keyName]: currentArray,
    });

    if (!changedFields.includes(keyName)) {
      setChangedField((oldArray) => [...oldArray, keyName]);
    }
  };

  // API Calls
  const getStandAloneResults = async (isIndex = false) => {
    try {
      const standAloneResultsInfo = await getIndex(`${controllerName}`);
      setStandAloneResults(standAloneResultsInfo);
      if (isIndex) {
        setFilterStandAloneResults(standAloneResultsInfo);
      }
    } catch (error: any) {
      throw new Error(error.message);
    }
  };

  const getStandAloneResult = async (id: number) => {
    try {
      const standAloneResultInfo = await getOne(id, `${controllerName}`);

      setStandAloneResult(standAloneResultInfo.result);
      setSavedName(standAloneResultInfo.result.title);
      setHealthCategories(standAloneResultInfo.healthCategories);
      setHealthAuthorityRegions(standAloneResultInfo.healthAuthorityRegions);
      setResearcherList(standAloneResultInfo.researchers);
      setHospitals(standAloneResultInfo.hospitals);
      setOriginalStatus(standAloneResultInfo.result.status);
    } catch (error) {
      throw new Error('error');
    }
  };

  const getStandAloneResultById = async (ids: number[]) => {
    try {
      const resultInfo = await getManyById(ids, `${controllerName}`);
      setDeleteResultIds(resultInfo);
    } catch (error) {
      throw new Error('error');
    }
  };

  const deleteResultsHandler = async (ids: number[]) => {
    try {
      await deleteMany(ids, `${controllerName}`);
      navigate(`/${baseUrl}`);
    } catch (error: any) {
      throw new Error(error.message);
    }
  };

  const getHistoryLog = async (id: string) => {
    try {
      const resResult = await getIndex(`${controllerName}/history/${id}`);
      setHistory(resResult.historyLogs);
      setStandAloneResult(resResult.resultInfo);
    } catch (error: any) {
      setErrors({ message: error.message });
    }
  };

  const messageHandler = (type: string, id: string) => {
    let message;

    if (type === 'edit') {
      message = createToastMessage('edit', nameSingle, `Stand Alone Result object (${id})`, `/${baseUrl}/${id}/change`);
      setToastMessage(message);
      setChangedField([]);
      return;
    }
    if (type === 'save') {
      message = createToastMessage('save', nameSingle, `Stand Alone Result object (${id})`, `/${baseUrl}/${id}/change`);
      navigate(`/${baseUrl}`, { state: { toastMessage: message } });
    }
  };

  const handleSubmitButton = async (type: string) => {
    try {
      if (!standAloneResult?.id) throw new Error(`Failed to update ${nameSingle}`);
      setErrors({});

      const resultInfo = {
        ...standAloneResult,
        health_categories: standAloneResult.health_categories?.filter((catgory: { value: any }) => catgory.value !== null),
        team_members: standAloneResult.team_members?.filter((team: { value: any }) => team.value !== null),
        updated_at: new Date(),
      };

      if (resourceFileDelete) {
        resultInfo.resource_file_name = 'null';
        resultInfo.resource_file_path = 'null';
      }

      const validedValues: any = await updateStandeAloneResultValidations.validate(resultInfo, { abortEarly: false, stripUnknown: true });

      const data = new FormData();
      validedValues.health_authority_region = validedValues.health_authority_region.filter((region: { value: any }) => region.value !== null);
      Object.keys(validedValues).forEach((key) => {
        const value = validedValues[key];

        if (key === 'updated_at') {
          data.append(key, new Date(value).toISOString());
        } else if (typeof value === 'object' && value !== null) {
          data.append(key, JSON.stringify(value));
        } else {
          data.append(key, value);
        }
      });

      if (resourceFile && !resourceFileDelete) {
        data.append('resourceFile', resourceFile);
      }

      data.append('changedFields', changedFields.join(','));
      data.append('originalStatus', originalStatus);

      const updatedResult = await updateData(data, `${controllerName}/${standAloneResult.id}`);
      messageHandler(type, updatedResult.id);

      setStandAloneResult(updatedResult);
      setResourceFileDelete(false);
    } catch (error: any) {
      if (error.inner) {
        const errorMessages: { [key: string]: string } = {};
        error.inner.forEach((element: any) => {
          errorMessages[element.path] = element.message;
          if (element.type === 'is-por-and-ppo') {
            errorMessages.is_ppo = element.message;
            errorMessages.is_por = element.message;
          }
          if (element.path.includes('team_member')) {
            errorMessages.teamMembers = element.message;
          }
          if (element.path.includes('health_categories')) {
            errorMessages.health_categories = element.message;
          }
        });
        setErrors(errorMessages);
      } else {
        setErrors(JSON.parse(error));
      }
    }
  };

  const handleUpdateFilter = (value: string | number, key: string) => {
    if (typeof value === 'number') return;
    switch (key) {
      case 'status':
        setResultStatusFilter(value);
        break;
      case 'search':
        setSearchFilter(value);
        break;
      default:
        break;
    }
  };

  const handleSearch = (query?: string) => {
    if (query === 'CLEAR') {
      setFilterStandAloneResults(standAloneResults);
      return;
    }

    if (!standAloneResults) return [];
    const searchValue = standAloneResults.filter((res) => {
      let result = false;
      const id = `${res.id}`;

      if (id?.includes(searchFilter)) {
        result = true;
      }

      if (res.title?.toLowerCase().includes(searchFilter)) {
        result = true;
      }

      if (resultStatusFilter !== '' && resultStatusFilter !== res.status) return false;

      if (res.nick_name?.toLowerCase().includes(searchFilter)) {
        return true;
      }

      if (res.pi_user?.toLowerCase().includes(searchFilter)) {
        return true;
      }

      if (res.created_by_user?.toLowerCase().includes(searchFilter)) {
        return true;
      }

      if (res.team_members) {
        const filterTeamMembers = res.team_members.filter((member) => member.toLowerCase().includes(searchFilter.toLowerCase()));
        if (filterTeamMembers.length > 0) return true;
      }
      return result;
    });

    setFilterStandAloneResults(searchValue);
    return searchValue;
  };

  useEffect(() => {
    handleSearch();
  }, [resultStatusFilter]);

  return {
    nameSingle,
    name,
    baseUrl,
    saveName,
    standAloneResult,
    filterStandAloneResults,
    standAloneResults,
    selectedStandAloneResults,
    pageType,
    setPageType,
    toastMessage,
    getStandAloneResult,
    setSelectedStandAloneResults,
    getStandAloneResults,
    setStandAloneResult,
    selectedValue,
    setSelectedValue,
    handleChange,
    handleSearch,
    handleCheck,
    errors,
    handleUpdateFilter,
    searchFilter,
    setSearchFilter,
    resultStatusFilter,
    resourceFile,
    setResourceFile,
    getBreadcrumbs,
    history,
    resourceFileDelete,
    setResourceFileDelete,
    researcherList,
    healthAuthorityRegions,
    hospitals,
    healthCategories,
    addRemoveListMembers,
    addRemoveAllListMembers,
    handleCheckArray,
    handleSubmitButton,

    deleteResultIds,
    getStandAloneResultById,
    deleteResultsHandler,

    getHistoryLog,
  };
};
