import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import AppWrapper from '../../../components/AppWrapper';
import PageTitle from '../../../components/PageTitle';
import TableHistory from '../../../components/TableHistory';
import { useStandAloneResult } from '../../../hooks';
import { historyColumns } from '../../../utils/const';
import { breachcrumbPath } from '../../../utils/breadcrumbConst';

export const StandAloneResultHistory = () => {
  const params = useParams();

  const hook = useStandAloneResult();

  useEffect(() => {
    if (params.id) {
      hook.getHistoryLog(params.id);
    }
  }, [params]);
  return (
    <AppWrapper
      breadcrumbs={[
        breachcrumbPath.core,
        breachcrumbPath.standAloneResult,
        { url: '', name: 'history' },
      ]}
    >
      <PageTitle
        title={`Change history: ${hook.standAloneResult?.title}`}
        linkUrl={`/${hook.baseUrl}/add`}
        buttonText={`add ${hook.nameSingle}`}
        useIcon
      />
      <div>
        <TableHistory
          columns={historyColumns}
          data={hook.history}
          footerText=""
        />
      </div>
    </AppWrapper>
  );
};
