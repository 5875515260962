export const STAND_ALONE_TITLES_OPTIONS: Record<string, string> = {
  change: 'Change ##nameSingle##',
  add: 'Add ##nameSingle##',
};

export const STAND_ALONE_RESULTS_FORM = {
  STATUS: {
    LABEL: 'Status:',
    NAME: 'status',
  },
  PI: {
    LABEL: 'Principal Investigator:',
    NAME: 'pi_email',
  },
  PPO: {
    LABEL: 'PPO project:',
    NAME: 'is_ppo',
  },
  PP_CO_INVESTIGATOR: {
    LABEL: 'Patient Partner Co-Investigator Name:',
    NAME: 'pp_co_investigator',
  },
  pp_AFFILIATION_ORGANIZATION: {
    LABEL: 'Patient Partner Affiliated Organization:',
    NAME: 'pp_affiliation_organization',
  },
  POR: {
    LABEL: 'POR project:',
    NAME: 'is_por',
  },
  TEAM_MEMBER: {
    LABEL: 'additional team members',
    NAME: 'team_members',
  },
  COLLABORATING_ORGANIZATION: {
    LABEL: 'Collaborating Organizations',
    NAME: 'collaborating_organizations',
  },
  AFFILIATION: {
    LABEL: 'BC SUPPORT Unit supported:',
    NAME: 'is_bc_support_unit_affiliation',
  },
  BC_SUPPORT_UNIT: {
    LABEL: 'Select Regional Centre(s):',
    NAME: 'regional_centers',
    DEFAULT: 'All',
  },
  TITLE: {
    LABEL: 'Study Title',
    NAME: 'title',
    COUNT: 500,
  },
  HEADLINE: {
    LABEL: 'Study Headline',
    NAME: 'nick_name',
    COUNT: 90,
  },
  PURPOSE: {
    LABEL: 'Study Purpose:',
    NAME: 'purpose',
    COUNT: 450,
  },
  HEALTH_CATEGORIES: {
    LABEL: 'health categories',
    NAME: 'health_categories',
  },
  REGIONS: {
    LABEL: 'Regions',
    NAME: 'health_authority_region',
  },

  CONACT_NAME: {
    LABEL: 'Full Name:',
    NAME: 'contact1_name',
    NAME_2: 'contact2_name',
    LIMIT: 250,
  },
  CONACT_POSITION: {
    LABEL: 'Position/Title:',
    NAME: 'contact1_position',
    NAME_2: 'contact2_position',
  },
  CONACT_EMAIL: {
    LABEL: 'Email:',
    NAME: 'contact1_email',
    NAME_2: 'contact2_email',
  },
  CONACT_PHONE: {
    LABEL: 'Phone Number:',
    NAME: 'contact1_phone',
    NAME_2: 'contact2_phone',
  },
  CONACT_EXTENSION: {
    LABEL: 'Extension:',
    NAME: 'contact1_phone_extension',
    NAME_2: 'contact2_phone_extension',
  },
  COMPLETED: {
    LABEL: 'Study completed:',
    NAME: 'is_completed',
  },
  SUMMARY: {
    LABEL: 'Summary of Results:',
    COUNT: 900,
    NAME: 'result_summary',
  },
  LIMITATIONS: {
    LABEL: 'Study Limitations',
    COUNT: 450,
    NAME: 'study_limitations',
  },
  ENGAGED: {
    LABEL: 'Number of Patient Partners engaged',
    NAME: 'patient_partners_engagement_number',
  },
  ENGAGEMENT_METHOD: {
    LABEL: 'How were Patient Partners engaged',
    NAME: 'patient_partners_engagement_method',
    COUNT: 900,
  },
  ONLINE: {
    LABEL: 'online:',
    NAME: 'is_online',
  },
  HOSPITAL: {
    LABEL: 'Hospital or Institution:',
    NAME: 'hospital_id',
  },
  BUILDING: {
    LABEL: 'Building:',
    NAME: 'building',
    COUNT: 120,
  },
  DEPARTMENT: {
    LABEL: 'Department:',
    NAME: 'department',
    COUNT: 120,
  },
  STREET: {
    LABEL: 'Street Address:',
    NAME: 'street',
    COUNT: 120,
  },
  CITY: {
    LABEL: 'City:',
    NAME: 'city',
    COUNT: 120,
  },
  POSTAL_CODE: {
    LABEL: 'Postal Code:',
    NAME: 'postal_code',
  },
  WEBSITE_URL: {
    LABEL: 'Weblink URL:',
    NAME: 'resource_website_url',
  },
  WEBSITE_NAME: {
    LABEL: 'Name of website/linked resource',
    NAME: 'resource_website_name',
  },
  FILE_NAME: {
    LABEL: 'resource file name:',
    NAME: 'resource_file_name',
  },
  DOCUMENT: {
    LABEL: 'Resource Filename:',
    NAME: 'resource_file',
  },
  COMMENT: {
    LABEL: 'Comments',
    NAME: 'comments',
  },
  ADMIN_COMMENT: {
    LABEL: 'Admin Comments',
    NAME: 'admin_comments',
  },
  UPDATED_BY: {
    LABEL: 'Created by:',
  },
  CREATED_BY: {
    LABEL: 'Last Updated by:',
  },
  CREATED_AT: {
    LABEL: ' created at:',
  },
  ADMIN_REVIEW: {
    LABEL: 'Admin to review',
    NAME: 'admin_to_review',
  },
};

export const VALIDATION_CONST = {
  ID: {
    TYPE: 'Invalid result ID',
    REQUIRED: 'Invalid result ID',
  },
  EMAIL: {
    TYPE: 'Please enter a valid email address',
    REQUIRED: 'Please enter a valid email address',
  },
  TEAM_MEMBERS: {
    TYPE: 'At least one team member is required',
    REQUIRED: 'Please enter a valid email address',
    VALID: 'An account with this email address does not exist. For this account to be saved, they must have an account associated with this email.',
    REMOVE: 'A new Additional Team Member must be added before you can remove this team member',
    REQUIRED_VALID: 'At least one team member is required',
  },
  STATUS: {
    TYPE: 'Status must be a string',
    REQUIRED: 'Invalid status',
  },
  PI_ID: {
    TYPE: 'PI ID must be a number',
    REQUIRED: 'A Researcher Account with this email address does not exist. The PI must create a researcher account before this study can be submitted.',
    VALID: 'A Researcher Account with this email address does not exist. The PI must create a researcher account before this study can be submitted.',
  },
  PI_EMAIL: {
    TYPE: 'PI email must be a string',
    REQUIRED: 'Please enter a valid email address',
  },
  TITLE: {
    TYPE: 'Title must be a string',
    REQUIRED: 'Please enter a valid title',
  },
  NICK_NAME: {
    TYPE: 'Lay title name must be a string',
    REQUIRED: 'Please enter a valid lay title',
  },
  PROJECT_PURPOSE: {
    TYPE: 'Project purpose must be a string',
    REQUIRED: 'Please enter a valid purpose',
  },
  RESULT_SUMMARY: {
    TYPE: 'Result summary must be a string',
    REQUIRED: 'Please enter a valid result summary',
  },
  STUDY_LIMITATIONS: {
    TYPE: 'Study limitations must be a string',
    REQUIRED: 'Please enter a valid study limitations',
  },
  IS_ONLINE: {
    TYPE: 'Is online must be a boolean',
    REQUIRED: 'Is online is required',
  },
  IS_COMPLETED: {
    TYPE: 'Is completed must be a boolean',
    REQUIRED: 'Is completed is required',
  },
  IS_PPO: {
    TYPE: 'Is ppo must be a boolean',
    REQUIRED: 'Is ppo is required',
  },
  IS_POR: {
    TYPE: 'Is por must be a boolean',
    REQUIRED: 'Is por is required',
  },
  IS_BC_SUPPORT_UNIT: {
    TYPE: 'IsBCSupportUnit must be a boolean',
    REQUIRED: 'IsBCSupportUnit is required',
  },
  RESOURCE_WEBSITE_NAME: {
    TYPE: 'Resource website name must be a string',
  },
  RESOURCE_WEBSITE_URL: {
    TYPE: 'Resource website URL must be a string',
  },
  RESOURCE_FILE_NAME: {
    TYPE: 'Resource file name must be a string',
  },
  RESOURCE_FILE_PATH: {
    TYPE: 'Resource path name must be a string',
  },
  COMMENTS: {
    TYPE: 'Comments must be a string',
    REQUIRED: 'Comments are required',
  },
  ADMIN_COMMENTS: {
    TYPE: 'Admin comments must be a string',
    REQUIRED: 'Admin comments are required',
  },
  CONTACT1_NAME: {
    TYPE: 'Contact 1 name must be a string',
    REQUIRED: 'Contact 1 name is required',
  },
  CONTACT1_POSITION: {
    TYPE: 'Contact 1 position must be a string',
    REQUIRED: 'Contact 1 position is required',
  },
  CONTACT1_EMAIL: {
    TYPE: 'Contact 1 email must be a string',
    REQUIRED: 'Contact 1 email is required',
  },
  CONTACT1_PHONE: {
    TYPE: 'Contact 1 phone must be a string',
    REQUIRED: 'Contact 1 phone is required',
  },
  CONTACT1_PHONE_EXTENSION: {
    TYPE: 'Contact 1 phone extension must be a string',
    REQUIRED: 'Contact 1 phone extension is required',
  },
  CONTACT2_NAME: {
    TYPE: 'Contact 2 name must be a string',
    REQUIRED: 'Contact 2 name is required',
  },
  CONTACT2_POSITION: {
    TYPE: 'Contact 2 position must be a string',
    REQUIRED: 'Contact 2 position is required',
  },
  CONTACT2_EMAIL: {
    TYPE: 'Contact 2 email must be a string',
    REQUIRED: 'Contact 2 email is required',
  },
  CONTACT2_PHONE: {
    TYPE: 'Contact 2 phone must be a string',
    REQUIRED: 'Contact 2 phone is required',
  },
  CONTACT2_PHONE_EXTENSION: {
    TYPE: 'Contact 2 phone extension must be a string',
    REQUIRED: 'Contact 2 phone extension is required',
  },

  HEALTH_CATEGORIES: {
    TYPE: 'Please select health categories',
    REQUIRED: 'Please select health categories',
    REQUIRED_VALID: 'At least one  health categories is required',
  },
  HEALTH_REGIONS: {
    TYPE: 'Please selects health authority regions',
    REQUIRED: 'Please selects health authority regions',
  },
  PP_CO_INVESTIGATOR: {
    TYPE: 'patient partner co-inverstigator must be a string',
    REQUIRED: 'Please enter patient partner co-investigator',
  },
  PP_ORGANIZATION: {
    TYPE: 'Patient Partner Affiliated organization must be a string',
    REQUIRED: 'Please enter Patient Partner Affiliated organization',
  },
  COLLABORATING_ORGANIZATION: {
    TYPE: 'Collaborating organization must be a string',
  },
  PHONE_NUMBER: {
    MATCH: 'Please enter a valid phone number',
    MIN: 'Please enter a valid phone number',
    MAX: 'Please enter a valid phone number. The field allows only 10 characters.',
  },
  ENGAGEMENT_METHOD: {
    TYPE: 'Engagement method must to be a string',
    REQUIRED: 'Please enter an engagement method',
  },
  ENGAGEMENT_NUMBER: {
    TYPE: 'Engagement method must to be a string',
    REQUIRED: 'Please enter an engagement method',
  },
  HOSPITAL: {
    TYPE: 'Hospital must be a string',
  },
  BUILDING: {
    TYPE: 'Building must be a string',
  },
  DEPARTMENT: {
    TYPE: 'Department must be a string',
  },
  STREET: {
    TYPE: 'Street address must be a string',
    REQUIRED: 'Please enter a street address',
  },
  CITY: {
    TYPE: 'City must be a string',
    REQUIRED: 'Please enter a city',
  },
  POSTAL_CODE: {
    TYPE: 'Postal code must be a string',
    MATCH: 'Please enter a valid postal code',
    REQUIRED: 'Please enter a postal code',
  },
  REGIONAL_CENTRE: {
    MIN: 'Please select a centre',
    REQUIRED: 'Please select a centre',
  },
  UPDATED_AT: {
    REQUIRED: 'Missing updated at date',
    TYPE: 'updated at must be a date',
  },
  ADMIN_TO_UPDATED: {
    REQUIRED: 'Missing admin to update value',
    TYPE: ' admin to update must be a boolean',
  },
};
