import React from 'react';
import {
  BrowserRouter, Routes, Route,
} from 'react-router-dom';
import P404 from './pages/404';
import Dashboard from './pages/Dashboard';
import CoreIndex from './pages/CoreIndex';
import HealthAuthority from './pages/HealthAuthority/Index';
import HealthAuthorityEdit from './pages/HealthAuthority/Edit';
import HealthAuthorityDelete from './pages/HealthAuthority/Delete';
import HealthAuthorityRegion from './pages/HealthAuthorityRegion/Index';
import HealthAuthorityRegionEdit from './pages/HealthAuthorityRegion/Edit';
import HealthAuthorityRegionDelete from './pages/HealthAuthorityRegion/Delete';
import Hospital from './pages/Hospital/Index';
import HospitalDelete from './pages/Hospital/Delete';
import HospitalEdit from './pages/Hospital/Edit';
import University from './pages/University/Index';
import UniversityEdit from './pages/University/Edit';
import UniversityDelete from './pages/University/Delete';
import HealthCategory from './pages/HealthCategory/Index';
import HealthCategoryEdit from './pages/HealthCategory/Edit';
import HealthCategoryDelete from './pages/HealthCategory/Delete';
import ProjectInterest from './pages/ProjectInterest/Index';
import ProjectInterestEdit from './pages/ProjectInterest/Edit';
import Project from './pages/Project/Index';
import ProjectEdit from './pages/Project/Edit';
import ProjectDelete from './pages/Project/Delete';
import StudyResults from './pages/StudyResult/Index';
import StudyResultEdit from './pages/StudyResult/Edit';
import StudyResultDelete from './pages/StudyResult/Delete';
import PpoResults from './pages/PpoResult/Index';
import PpoResultEdit from './pages/PpoResult/Edit';
import PpoResultDelete from './pages/PpoResult/Delete';
import User from './pages/User/Index';
import UserEdit from './pages/User/Edit';
import Researcher from './pages/Researcher/Index';
import ResearcherEdit from './pages/Researcher/Edit';
import Volunteer from './pages/Volunteer/Index';
import VolunteerEdit from './pages/Volunteer/Edit';
import VolunteerDelete from './pages/Volunteer/Delete';
import AdminIndex from './pages/AdminIndex';
import AdminUser from './pages/AdminUser/Index';
import AdminUserEdit from './pages/AdminUser/Edit';
import Login from './pages/Login';
import ChangePassword from './pages/ChangePassword';
import ForcePasswordChange from './pages/ForcePasswordChange';
import UploadCsv from './pages/UploadCsv';
import GeneratorEmailList from './pages/GeneratorEmailList';
import ProjectHistory from './pages/Project/History';
import ProjectInterestHistory from './pages/ProjectInterest/History';
import StudyResultHistory from './pages/StudyResult/History';
import PPOResultHistory from './pages/PpoResult/History';
import ProtectedRoute from './components/ProtectedRoute';
import UserDelete from './pages/User/Delete';
import GeneratorTablesCsv from './pages/GeneratorTablesCsv';
import { StandAloneResult } from './pages/StandAloneResult/Index';
import { StandAloneResultEdit } from './pages/StandAloneResult/Edit';
import { StandAloneResultDelete } from './pages/StandAloneResult/Delete';
import { StandAloneResultHistory } from './pages/StandAloneResult/History';

const Router: React.FC = () => (
  <BrowserRouter>
    <Routes>
      <Route
        path="/"
        element={<ProtectedRoute><Dashboard /> </ProtectedRoute>}
      />
      <Route path="/login" element={<Login />} />
      <Route path="/passwordChange" element={<ProtectedRoute><ChangePassword /></ProtectedRoute>} />
      <Route path="/authChallenge" element={<ForcePasswordChange />} />
      <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />

      <Route path="core">
        <Route path="" element={<ProtectedRoute><CoreIndex /></ProtectedRoute>} />

        <Route path="healthauthority">
          <Route path="" element={<ProtectedRoute><HealthAuthority /></ProtectedRoute>} />
          <Route path=":id/change" element={<ProtectedRoute><HealthAuthorityEdit /></ProtectedRoute>} />
          <Route path="add" element={<ProtectedRoute><HealthAuthorityEdit /></ProtectedRoute>} />
          <Route path=":id/delete" element={<ProtectedRoute><HealthAuthorityDelete /></ProtectedRoute>} />
          <Route path="delete" element={<ProtectedRoute><HealthAuthorityDelete /></ProtectedRoute>} />
        </Route>

        <Route path="healthauthorityregion">
          <Route path="" element={<ProtectedRoute><HealthAuthorityRegion /></ProtectedRoute>} />
          <Route path=":id/change" element={<ProtectedRoute><HealthAuthorityRegionEdit /></ProtectedRoute>} />
          <Route path="add" element={<ProtectedRoute><HealthAuthorityRegionEdit /></ProtectedRoute>} />
          <Route path=":id/delete" element={<ProtectedRoute><HealthAuthorityRegionDelete /></ProtectedRoute>} />
          <Route path="delete" element={<ProtectedRoute><HealthAuthorityRegionDelete /></ProtectedRoute>} />
        </Route>

        <Route path="hospital">
          <Route path="" element={<ProtectedRoute><Hospital /></ProtectedRoute>} />
          <Route path=":id/change" element={<ProtectedRoute><HospitalEdit /></ProtectedRoute>} />
          <Route path="add" element={<ProtectedRoute><HospitalEdit /></ProtectedRoute>} />
          <Route path=":id/delete" element={<ProtectedRoute><HospitalDelete /></ProtectedRoute>} />
          <Route path="delete" element={<ProtectedRoute><HospitalDelete /></ProtectedRoute>} />
        </Route>

        <Route path="healthcategory">
          <Route path="" element={<ProtectedRoute><HealthCategory /></ProtectedRoute>} />
          <Route path=":id/change" element={<ProtectedRoute><HealthCategoryEdit /></ProtectedRoute>} />
          <Route path="add" element={<ProtectedRoute><HealthCategoryEdit /></ProtectedRoute>} />
          <Route path=":id/delete" element={<ProtectedRoute><HealthCategoryDelete /></ProtectedRoute>} />
          <Route path="delete" element={<ProtectedRoute><HealthCategoryDelete /></ProtectedRoute>} />
        </Route>

        <Route path="university">
          <Route path="" element={<ProtectedRoute><University /></ProtectedRoute>} />
          <Route path=":id/change" element={<ProtectedRoute><UniversityEdit /></ProtectedRoute>} />
          <Route path="add" element={<ProtectedRoute><UniversityEdit /></ProtectedRoute>} />
          <Route path=":id/delete" element={<ProtectedRoute><UniversityDelete /></ProtectedRoute>} />
          <Route path="delete" element={<ProtectedRoute><UniversityDelete /></ProtectedRoute>} />
        </Route>

        <Route path="projectinterest">
          <Route path="" element={<ProtectedRoute><ProjectInterest /></ProtectedRoute>} />
          <Route path=":id/change" element={<ProtectedRoute><ProjectInterestEdit /></ProtectedRoute>} />
          <Route path="add" element={<ProtectedRoute><ProjectInterestEdit /></ProtectedRoute>} />
          <Route path=":id/history" element={<ProtectedRoute><ProjectInterestHistory /></ProtectedRoute>} />
        </Route>

        <Route path="project">
          <Route path="" element={<ProtectedRoute><Project /></ProtectedRoute>} />
          <Route path=":id/change" element={<ProtectedRoute><ProjectEdit /></ProtectedRoute>} />
          <Route path=":id/delete" element={<ProtectedRoute><ProjectDelete /></ProtectedRoute>} />
          <Route path="delete" element={<ProtectedRoute><ProjectDelete /></ProtectedRoute>} />
          <Route path=":id/history" element={<ProtectedRoute><ProjectHistory /></ProtectedRoute>} />
        </Route>

        <Route path="studyResult">
          <Route path="" element={<ProtectedRoute><StudyResults /></ProtectedRoute>} />
          <Route path=":id/change" element={<ProtectedRoute><StudyResultEdit /></ProtectedRoute>} />
          <Route path=":id/delete" element={<ProtectedRoute><StudyResultDelete /></ProtectedRoute>} />
          <Route path=":id/history" element={<ProtectedRoute><StudyResultHistory /></ProtectedRoute>} />
        </Route>

        <Route path="ppoResult">
          <Route path="" element={<ProtectedRoute><PpoResults /></ProtectedRoute>} />
          <Route path=":id/change" element={<ProtectedRoute><PpoResultEdit /></ProtectedRoute>} />
          <Route path=":id/delete" element={<ProtectedRoute><PpoResultDelete /></ProtectedRoute>} />
          <Route path=":id/history" element={<ProtectedRoute><PPOResultHistory /></ProtectedRoute>} />
        </Route>

        <Route path="standAloneResult">
          <Route path="" element={<ProtectedRoute><StandAloneResult /></ProtectedRoute>} />
          <Route path=":id/change" element={<ProtectedRoute><StandAloneResultEdit /></ProtectedRoute>} />
          <Route path=":id/delete" element={<ProtectedRoute><StandAloneResultDelete /></ProtectedRoute>} />
          <Route path=":id/history" element={<ProtectedRoute><StandAloneResultHistory /></ProtectedRoute>} />

        </Route>

        <Route path="user">
          <Route path="" element={<ProtectedRoute><User /></ProtectedRoute>} />
          <Route path=":id/change" element={<ProtectedRoute><UserEdit /></ProtectedRoute>} />
          <Route path="add" element={<ProtectedRoute><UserEdit /></ProtectedRoute>} />
          <Route path=":id/delete" element={<ProtectedRoute><UserDelete /></ProtectedRoute>} />
          <Route path="delete" element={<ProtectedRoute><UserDelete /></ProtectedRoute>} />
        </Route>

        <Route path="researcher">
          <Route path="" element={<ProtectedRoute><Researcher /></ProtectedRoute>} />
          <Route path=":id/change" element={<ProtectedRoute><ResearcherEdit /></ProtectedRoute>} />
          <Route path="add" element={<ProtectedRoute><ResearcherEdit /></ProtectedRoute>} />
        </Route>

        <Route path="volunteer">
          <Route path="" element={<ProtectedRoute><Volunteer /></ProtectedRoute>} />
          <Route path=":id/change" element={<ProtectedRoute><VolunteerEdit /></ProtectedRoute>} />
          <Route path="add" element={<ProtectedRoute><VolunteerEdit /></ProtectedRoute>} />
          <Route path=":id/delete" element={<ProtectedRoute><VolunteerDelete /></ProtectedRoute>} />
          <Route path="delete" element={<ProtectedRoute><VolunteerDelete /></ProtectedRoute>} />
        </Route>
      </Route>

      <Route path="admin">
        <Route path="" element={<ProtectedRoute><AdminIndex /></ProtectedRoute>} />
        <Route path="adminUser">
          <Route path="" element={<ProtectedRoute><AdminUser /></ProtectedRoute>} />
          <Route path=":id/change" element={<ProtectedRoute><AdminUserEdit /></ProtectedRoute>} />
          <Route path="add" element={<ProtectedRoute><AdminUserEdit /></ProtectedRoute>} />
        </Route>

        <Route path="csvUploader">
          <Route path="add" element={<ProtectedRoute><UploadCsv /></ProtectedRoute>} />
        </Route>

        <Route path="generatorList">
          <Route path="add" element={<ProtectedRoute><GeneratorEmailList /></ProtectedRoute>} />
        </Route>

        <Route path="generatorTableCvs">
          <Route path="add" element={<ProtectedRoute><GeneratorTablesCsv /></ProtectedRoute>} />
        </Route>
      </Route>

      <Route path="*" element={<P404 />} />
    </Routes>

  </BrowserRouter>
);

export default Router;
