import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import AppWrapper from '../../../components/AppWrapper';
import { breachcrumbPath } from '../../../utils/breadcrumbConst';
import { useStandAloneResult } from '../../../hooks';
import { STAND_ALONE_RESULTS_FORM, STAND_ALONE_TITLES_OPTIONS } from './const';
import PageTitle from '../../../components/PageTitle';
import * as Styled from '../../../styles/EditDeleteStyles';
import ErrorMessage from '../../../components/ErrorMessage';
import InputCheckbox from '../../../components/InputCheckbox/Index';
import { TextArea } from '../../../components/InputTextArea';
import InputSelect from '../../../components/InputSelect';
import InputText from '../../../components/InputText';
import InputAddRemove from '../../../components/InputAddRemove';
import CheckboxGroup from '../../../components/CheckboxGroup';
import { regionalCentersOptions, researcherPositionOptions, resultStatusOptions } from '../../../utils/const';
import InputNumber from '../../../components/InputNumber';
import { FileInput } from '../../../components/FileInput';
import FormLabelText from '../../../components/FormLabelText';
import EditFooterBar from '../../../components/EditFooterBar';

export const StandAloneResultEdit = () => {
  const params = useParams();

  const hook = useStandAloneResult();

  useEffect(() => {
    if (params.id) {
      hook.getStandAloneResult(parseInt(params.id, 10));
    }
  }, [params]);

  return (
    <AppWrapper
      toastMessage={hook.toastMessage}
      breadcrumbs={[
        breachcrumbPath.core,
        breachcrumbPath.standAloneResult,
        { url: '', name: `Stand Alone Result object (${hook.standAloneResult?.id})` },
      ]}
    >

      <PageTitle
        title={STAND_ALONE_TITLES_OPTIONS[hook.pageType].replace('##nameSingle##', hook.nameSingle)}
        linkUrl={`/${hook.baseUrl}/${hook.standAloneResult?.id}/history`}
        buttonText="history"
        showButton={hook.pageType === 'change'}
      />
      <Styled.ObjectTitleStyled>{hook.saveName}</Styled.ObjectTitleStyled>
      {Object.keys(hook.errors).length > 0 && (
        <ErrorMessage message={hook.errors.fetch} />
      )}

      <Styled.FormWrapper>
        <Styled.InputWrapper>
          <InputCheckbox
            label={STAND_ALONE_RESULTS_FORM.COMPLETED.LABEL}
            checkedValue={hook.standAloneResult?.is_completed || false}
            handleChange={(value) => hook.handleChange(value, STAND_ALONE_RESULTS_FORM.COMPLETED.NAME)}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <TextArea
            label={STAND_ALONE_RESULTS_FORM.TITLE.LABEL}
            maxLenght={STAND_ALONE_RESULTS_FORM.TITLE.COUNT}
            textValue={hook.standAloneResult?.title || ''}
            handleHandle={(value) => hook.handleChange(value, STAND_ALONE_RESULTS_FORM.TITLE.NAME)}
            keyName={STAND_ALONE_RESULTS_FORM.TITLE.NAME}
            error={hook.errors.title}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <TextArea
            label={STAND_ALONE_RESULTS_FORM.HEADLINE.LABEL}
            maxLenght={STAND_ALONE_RESULTS_FORM.HEADLINE.COUNT}
            textValue={hook.standAloneResult?.nick_name || ''}
            handleHandle={(value) => hook.handleChange(value, STAND_ALONE_RESULTS_FORM.HEADLINE.NAME)}
            keyName={STAND_ALONE_RESULTS_FORM.HEADLINE.NAME}
            error={hook.errors.nick_name}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputSelect
            label="Principal Investigator:"
            onChange={(value) => hook.handleChange(value, 'pi_id')}
            options={hook.researcherList}
            placeholder="----------"
            selectedValue={hook.standAloneResult?.pi_id || ''}
            error={hook.errors.pi_id}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputCheckbox
            label={STAND_ALONE_RESULTS_FORM.PPO.LABEL}
            checkedValue={hook.standAloneResult?.is_ppo || false}
            handleChange={(value) => hook.handleChange(value, STAND_ALONE_RESULTS_FORM.PPO.NAME)}
            error={hook.errors.is_ppo}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputCheckbox
            label={STAND_ALONE_RESULTS_FORM.POR.LABEL}
            checkedValue={hook.standAloneResult?.is_por || false}
            handleChange={(value) => hook.handleChange(value, STAND_ALONE_RESULTS_FORM.POR.NAME)}
            error={hook.errors.is_por}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputText
            id={STAND_ALONE_RESULTS_FORM.PP_CO_INVESTIGATOR.NAME}
            label={STAND_ALONE_RESULTS_FORM.PP_CO_INVESTIGATOR.LABEL}
            inputValue={`${hook.standAloneResult?.pp_co_investigator}` || ''}
            handleHandle={hook.handleChange}
            keyName={STAND_ALONE_RESULTS_FORM.PP_CO_INVESTIGATOR.NAME}
            padding={false}
            theme="form"
            error={hook.errors.pp_co_investigator}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputText
            id={STAND_ALONE_RESULTS_FORM.pp_AFFILIATION_ORGANIZATION.NAME}
            label={STAND_ALONE_RESULTS_FORM.pp_AFFILIATION_ORGANIZATION.LABEL}
            inputValue={`${hook.standAloneResult?.pp_affiliation_organization}` || ''}
            handleHandle={hook.handleChange}
            keyName={STAND_ALONE_RESULTS_FORM.pp_AFFILIATION_ORGANIZATION.NAME}
            padding={false}
            theme="form"
            error={hook.errors.pp_affiliation_organization}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputNumber
            id={STAND_ALONE_RESULTS_FORM.ENGAGED.NAME}
            label={STAND_ALONE_RESULTS_FORM.ENGAGED.LABEL}
            inputValue={hook.standAloneResult?.patient_partners_engagement_number}
            handleHandle={hook.handleChange}
            keyName={STAND_ALONE_RESULTS_FORM.ENGAGED.NAME}
            padding={false}
            error={hook.errors.patient_partners_engagement_number}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <TextArea
            id={STAND_ALONE_RESULTS_FORM.ENGAGEMENT_METHOD.NAME}
            label={STAND_ALONE_RESULTS_FORM.ENGAGEMENT_METHOD.LABEL}
            textValue={`${hook.standAloneResult?.patient_partners_engagement_method}` || ''}
            handleHandle={hook.handleChange}
            keyName={STAND_ALONE_RESULTS_FORM.ENGAGEMENT_METHOD.NAME}
            error={hook.errors.patient_partners_engagement_method}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <TextArea
            id={STAND_ALONE_RESULTS_FORM.COLLABORATING_ORGANIZATION.NAME}
            label={STAND_ALONE_RESULTS_FORM.COLLABORATING_ORGANIZATION.LABEL}
            textValue={hook.standAloneResult?.collaborating_organizations || ''}
            handleHandle={(value) => hook.handleChange(value, STAND_ALONE_RESULTS_FORM.COLLABORATING_ORGANIZATION.NAME)}
            keyName={STAND_ALONE_RESULTS_FORM.COLLABORATING_ORGANIZATION.NAME}
            error={hook.errors.collaborating_organizations}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputAddRemove
            allOptionsList={hook.researcherList}
            selectOptions={hook.standAloneResult?.team_members || []}
            handleUpdate={(list, add) => hook.addRemoveListMembers(
              list,
              add,
              STAND_ALONE_RESULTS_FORM.TEAM_MEMBER.NAME,
            )}
            handleAllUpdate={(add) => hook.addRemoveAllListMembers(add, STAND_ALONE_RESULTS_FORM.TEAM_MEMBER.NAME)}
            label={STAND_ALONE_RESULTS_FORM.TEAM_MEMBER.LABEL}
            error={hook.errors.teamMembers}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputCheckbox
            label={STAND_ALONE_RESULTS_FORM.AFFILIATION.LABEL}
            checkedValue={hook.standAloneResult?.is_bc_support_unit_affiliation || false}
            handleChange={(value) => hook.handleChange(value, STAND_ALONE_RESULTS_FORM.AFFILIATION.NAME)}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <CheckboxGroup
            checkboxOptions={regionalCentersOptions}
            selectValues={hook.standAloneResult?.regional_centers || []}
            mainLabel={STAND_ALONE_RESULTS_FORM.BC_SUPPORT_UNIT.LABEL}
            handleChange={
              (checked, value) => hook.handleCheckArray(checked, value, STAND_ALONE_RESULTS_FORM.BC_SUPPORT_UNIT.NAME)
            }
            error={hook.errors.regional_centers}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <TextArea
            label={STAND_ALONE_RESULTS_FORM.PURPOSE.LABEL}
            textValue={hook.standAloneResult?.purpose || ''}
            handleHandle={(value) => hook.handleChange(value, STAND_ALONE_RESULTS_FORM.PURPOSE.NAME)}
            maxLenght={STAND_ALONE_RESULTS_FORM.PURPOSE.COUNT}
            keyName={STAND_ALONE_RESULTS_FORM.PURPOSE.NAME}
            error={hook.errors.purpose}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <TextArea
            label={STAND_ALONE_RESULTS_FORM.SUMMARY.LABEL}
            textValue={hook.standAloneResult?.result_summary || ''}
            handleHandle={(value) => hook.handleChange(value, STAND_ALONE_RESULTS_FORM.SUMMARY.NAME)}
            maxLenght={STAND_ALONE_RESULTS_FORM.SUMMARY.COUNT}
            keyName={STAND_ALONE_RESULTS_FORM.SUMMARY.NAME}
            error={hook.errors.result_summary}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <TextArea
            label={STAND_ALONE_RESULTS_FORM.LIMITATIONS.LABEL}
            textValue={hook.standAloneResult?.study_limitations || ''}
            handleHandle={(value) => hook.handleChange(value, STAND_ALONE_RESULTS_FORM.LIMITATIONS.NAME)}
            maxLenght={STAND_ALONE_RESULTS_FORM.LIMITATIONS.COUNT}
            keyName={STAND_ALONE_RESULTS_FORM.LIMITATIONS.NAME}
            error={hook.errors.study_limitations}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputAddRemove
            allOptionsList={hook.healthCategories}
            handleUpdate={(list, add) => hook.addRemoveListMembers(
              list,
              add,
              STAND_ALONE_RESULTS_FORM.HEALTH_CATEGORIES.NAME,
            )}
            selectOptions={hook.standAloneResult?.health_categories || []}
            handleAllUpdate={(add) => hook.addRemoveAllListMembers(add, STAND_ALONE_RESULTS_FORM.HEALTH_CATEGORIES.NAME)}
            label={STAND_ALONE_RESULTS_FORM.HEALTH_CATEGORIES.LABEL}
            error={hook.errors.health_categories}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputAddRemove
            allOptionsList={hook.healthAuthorityRegions}
            selectOptions={hook.standAloneResult?.health_authority_region || []}
            handleAllUpdate={(add) => hook.addRemoveAllListMembers(add, STAND_ALONE_RESULTS_FORM.REGIONS.NAME)}
            handleUpdate={(list, add) => hook.addRemoveListMembers(
              list,
              add,
              STAND_ALONE_RESULTS_FORM.REGIONS.NAME,
            )}
            label={STAND_ALONE_RESULTS_FORM.REGIONS.LABEL}
            error={hook.errors.health_authority_region || hook.errors.healthAuthorityRegions}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputCheckbox
            label={STAND_ALONE_RESULTS_FORM.ONLINE.LABEL}
            checkedValue={hook.standAloneResult?.is_online || false}
            handleChange={(value) => hook.handleChange(value, STAND_ALONE_RESULTS_FORM.ONLINE.NAME)}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputSelect
            label="hospitals:"
            onChange={(value) => hook.handleChange(value, 'hospital_id')}
            options={hook.hospitals}
            placeholder="----------"
            selectedValue={hook.standAloneResult?.hospital_id || ''}
            error={hook.errors.hospital_id}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputText
            id={STAND_ALONE_RESULTS_FORM.BUILDING.NAME}
            label={STAND_ALONE_RESULTS_FORM.BUILDING.LABEL}
            inputValue={`${hook.standAloneResult?.building}` || ''}
            handleHandle={hook.handleChange}
            keyName={STAND_ALONE_RESULTS_FORM.BUILDING.NAME}
            padding={false}
            theme="form"
            error={hook.errors.building}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputText
            id={STAND_ALONE_RESULTS_FORM.DEPARTMENT.NAME}
            label={STAND_ALONE_RESULTS_FORM.DEPARTMENT.LABEL}
            inputValue={`${hook.standAloneResult?.department}` || ''}
            handleHandle={hook.handleChange}
            keyName={STAND_ALONE_RESULTS_FORM.DEPARTMENT.NAME}
            padding={false}
            theme="form"
            error={hook.errors.department}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputText
            id={STAND_ALONE_RESULTS_FORM.STREET.NAME}
            label={STAND_ALONE_RESULTS_FORM.STREET.LABEL}
            inputValue={`${hook.standAloneResult?.street}` || ''}
            handleHandle={hook.handleChange}
            keyName={STAND_ALONE_RESULTS_FORM.STREET.NAME}
            padding={false}
            theme="form"
            error={hook.errors.street}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputText
            id={STAND_ALONE_RESULTS_FORM.CITY.NAME}
            label={STAND_ALONE_RESULTS_FORM.CITY.LABEL}
            inputValue={`${hook.standAloneResult?.city}` || ''}
            handleHandle={hook.handleChange}
            keyName={STAND_ALONE_RESULTS_FORM.CITY.NAME}
            padding={false}
            theme="form"
            error={hook.errors.city}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputText
            id={STAND_ALONE_RESULTS_FORM.POSTAL_CODE.NAME}
            label={STAND_ALONE_RESULTS_FORM.POSTAL_CODE.LABEL}
            inputValue={`${hook.standAloneResult?.postal_code}` || ''}
            handleHandle={hook.handleChange}
            keyName={STAND_ALONE_RESULTS_FORM.POSTAL_CODE.NAME}
            padding={false}
            theme="form"
            error={hook.errors.postal_code}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputText
            id={STAND_ALONE_RESULTS_FORM.CONACT_NAME.NAME}
            label={STAND_ALONE_RESULTS_FORM.CONACT_NAME.LABEL}
            inputValue={`${hook.standAloneResult?.contact1_name}` || ''}
            handleHandle={hook.handleChange}
            keyName="contact1_name"
            padding={false}
            theme="form"
            error={hook.errors.contact1_name}
          />
        </Styled.InputWrapper>
        <Styled.InputWrapper>
          <InputSelect
            label={STAND_ALONE_RESULTS_FORM.CONACT_POSITION.LABEL}
            onChange={(value) => hook.handleChange(value, STAND_ALONE_RESULTS_FORM.CONACT_POSITION.NAME)}
            options={researcherPositionOptions}
            placeholder="----------"
            selectedValue={hook.standAloneResult?.contact1_position || ''}
            error={hook.errors.contact1_position}
          />
        </Styled.InputWrapper>
        <Styled.InputWrapper>
          <InputText
            id={STAND_ALONE_RESULTS_FORM.CONACT_EMAIL.NAME}
            label={STAND_ALONE_RESULTS_FORM.CONACT_EMAIL.LABEL}
            inputValue={`${hook.standAloneResult?.contact1_email}` || ''}
            handleHandle={hook.handleChange}
            keyName={STAND_ALONE_RESULTS_FORM.CONACT_EMAIL.NAME}
            padding={false}
            theme="form"
            error={hook.errors.contact1_email}
          />
        </Styled.InputWrapper>
        <Styled.InputWrapper>
          <InputText
            id={STAND_ALONE_RESULTS_FORM.CONACT_PHONE.NAME}
            label={STAND_ALONE_RESULTS_FORM.CONACT_PHONE.LABEL}
            inputValue={`${hook.standAloneResult?.contact1_phone}` || ''}
            handleHandle={hook.handleChange}
            keyName={STAND_ALONE_RESULTS_FORM.CONACT_PHONE.NAME}
            padding={false}
            theme="form"
            error={hook.errors.contact1_phone}
          />
        </Styled.InputWrapper>
        <Styled.InputWrapper>
          <InputText
            id={STAND_ALONE_RESULTS_FORM.CONACT_EXTENSION.NAME}
            label={STAND_ALONE_RESULTS_FORM.CONACT_EXTENSION.LABEL}
            inputValue={`${hook.standAloneResult?.contact1_phone_extension}` || ''}
            handleHandle={hook.handleChange}
            keyName={STAND_ALONE_RESULTS_FORM.CONACT_EXTENSION.NAME}
            padding={false}
            theme="form"
            error={hook.errors.contact1_phone_extension}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputText
            id={STAND_ALONE_RESULTS_FORM.CONACT_NAME.NAME_2}
            label={STAND_ALONE_RESULTS_FORM.CONACT_NAME.LABEL}
            inputValue={`${hook.standAloneResult?.contact2_name}` || ''}
            handleHandle={hook.handleChange}
            keyName="contact2_name"
            padding={false}
            theme="form"
            error={hook.errors.contact2_name}
          />
        </Styled.InputWrapper>
        <Styled.InputWrapper>
          <InputSelect
            label={STAND_ALONE_RESULTS_FORM.CONACT_POSITION.LABEL}
            onChange={(value) => hook.handleChange(value, STAND_ALONE_RESULTS_FORM.CONACT_POSITION.NAME_2)}
            options={researcherPositionOptions}
            placeholder="----------"
            selectedValue={hook.standAloneResult?.contact2_position || ''}
            error={hook.errors.contact2_position}
          />
        </Styled.InputWrapper>
        <Styled.InputWrapper>
          <InputText
            id={STAND_ALONE_RESULTS_FORM.CONACT_EMAIL.NAME_2}
            label={STAND_ALONE_RESULTS_FORM.CONACT_EMAIL.LABEL}
            inputValue={`${hook.standAloneResult?.contact2_email}` || ''}
            handleHandle={hook.handleChange}
            keyName={STAND_ALONE_RESULTS_FORM.CONACT_EMAIL.NAME_2}
            padding={false}
            theme="form"
            error={hook.errors.contact2_email}
          />
        </Styled.InputWrapper>
        <Styled.InputWrapper>
          <InputText
            id={STAND_ALONE_RESULTS_FORM.CONACT_PHONE.NAME_2}
            label={STAND_ALONE_RESULTS_FORM.CONACT_PHONE.LABEL}
            inputValue={`${hook.standAloneResult?.contact2_phone}` || ''}
            handleHandle={hook.handleChange}
            keyName={STAND_ALONE_RESULTS_FORM.CONACT_PHONE.NAME_2}
            padding={false}
            theme="form"
            error={hook.errors.contact2_phone}
          />
        </Styled.InputWrapper>
        <Styled.InputWrapper>
          <InputText
            id={STAND_ALONE_RESULTS_FORM.CONACT_EXTENSION.NAME_2}
            label={STAND_ALONE_RESULTS_FORM.CONACT_EXTENSION.LABEL}
            inputValue={`${hook.standAloneResult?.contact2_phone_extension}` || ''}
            handleHandle={hook.handleChange}
            keyName={STAND_ALONE_RESULTS_FORM.CONACT_EXTENSION.NAME_2}
            padding={false}
            theme="form"
            error={hook.errors.contact2_phone_extension}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputText
            id={STAND_ALONE_RESULTS_FORM.WEBSITE_NAME.NAME}
            label={STAND_ALONE_RESULTS_FORM.WEBSITE_NAME.LABEL}
            inputValue={`${hook.standAloneResult?.resource_website_name}` || ''}
            handleHandle={hook.handleChange}
            keyName={STAND_ALONE_RESULTS_FORM.WEBSITE_NAME.NAME}
            padding={false}
            theme="form"
            error={hook.errors.resource_website_name}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputText
            id={STAND_ALONE_RESULTS_FORM.WEBSITE_URL.NAME}
            label={STAND_ALONE_RESULTS_FORM.WEBSITE_URL.LABEL}
            inputValue={`${hook.standAloneResult?.resource_website_url}` || ''}
            handleHandle={hook.handleChange}
            keyName={STAND_ALONE_RESULTS_FORM.WEBSITE_URL.NAME}
            padding={false}
            theme="form"
            error={hook.errors.resource_website_url}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputText
            id={STAND_ALONE_RESULTS_FORM.FILE_NAME.NAME}
            label={STAND_ALONE_RESULTS_FORM.FILE_NAME.LABEL}
            inputValue={`${hook.standAloneResult?.resource_file_name}` || ''}
            handleHandle={hook.handleChange}
            keyName={STAND_ALONE_RESULTS_FORM.FILE_NAME.NAME}
            padding={false}
            theme="form"
            error={hook.errors.resource_file_name}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <FileInput
            label={STAND_ALONE_RESULTS_FORM.DOCUMENT.LABEL}
            handleChange={(value) => hook.setResourceFile(value)}
            field={STAND_ALONE_RESULTS_FORM.DOCUMENT.NAME}
            fileLink={hook.standAloneResult?.resourceFile}
            fileName={hook.resourceFile?.name || `${hook.standAloneResult?.resource_file_path}` || ''}
            isChecked={hook.resourceFileDelete}
            handleCheck={hook.setResourceFileDelete}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <TextArea
            label={STAND_ALONE_RESULTS_FORM.COMMENT.LABEL}
            textValue={hook.standAloneResult?.comments || ''}
            handleHandle={(value) => hook.handleChange(value, STAND_ALONE_RESULTS_FORM.COMMENT.NAME)}
            keyName={STAND_ALONE_RESULTS_FORM.COMMENT.NAME}
            error={hook.errors.comments}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <TextArea
            label={STAND_ALONE_RESULTS_FORM.ADMIN_COMMENT.LABEL}
            textValue={hook.standAloneResult?.admin_comments || ''}
            handleHandle={(value) => hook.handleChange(value, STAND_ALONE_RESULTS_FORM.ADMIN_COMMENT.NAME)}
            keyName={STAND_ALONE_RESULTS_FORM.ADMIN_COMMENT.NAME}
            error={hook.errors.admin_comments}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputSelect
            label={STAND_ALONE_RESULTS_FORM.STATUS.LABEL}
            onChange={(value) => hook.handleChange(value, STAND_ALONE_RESULTS_FORM.STATUS.NAME)}
            options={resultStatusOptions}
            selectedValue={hook.standAloneResult?.status || ''}
          />
        </Styled.InputWrapper>
        <Styled.InputWrapper>
          <FormLabelText
            label={STAND_ALONE_RESULTS_FORM.UPDATED_BY.LABEL}
            textValue={hook.standAloneResult?.updated_by_user || '-'}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <FormLabelText
            label={STAND_ALONE_RESULTS_FORM.CREATED_AT.LABEL}
            textValue={hook.standAloneResult?.created_at || '-'}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <FormLabelText
            label={STAND_ALONE_RESULTS_FORM.CREATED_BY.LABEL}
            textValue={hook.standAloneResult?.created_by || '-'}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputCheckbox
            label={STAND_ALONE_RESULTS_FORM.ADMIN_REVIEW.LABEL}
            checkedValue={hook.standAloneResult?.admin_to_review || false}
            handleChange={(value) => hook.handleChange(value, STAND_ALONE_RESULTS_FORM.ADMIN_REVIEW.NAME)}
          />
        </Styled.InputWrapper>

      </Styled.FormWrapper>

      <EditFooterBar
        handleSubmit={hook.handleSubmitButton}
        urlPath={`${hook.baseUrl}/${hook.standAloneResult?.id}`}
        showNew={false}
        showDelete
      />
    </AppWrapper>
  );
};
