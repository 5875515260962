import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import AppWrapper from '../../../components/AppWrapper';
import Button from '../../../components/Button';
import { LinkComponent } from '../../../components/Link';
import { TextH3, TextP } from '../../../components/Text';
import { useStandAloneResult } from '../../../hooks';
import * as Styled from '../../../styles/EditDeleteStyles';
import { fonts } from '../../../styles/theme';
import { STAND_ALONE_RESULT_DELETE } from './const';

export const StandAloneResultDelete = () => {
  const params = useParams();

  const hook = useStandAloneResult();

  useEffect(() => {
    if (params.id) {
      hook.getStandAloneResultById([parseInt(params.id, 10)]);
    }
  }, [params]);

  return (
    <AppWrapper breadcrumbs={hook.getBreadcrumbs()}>
      <Styled.TextWrapper>
        <TextH3 font={fonts.font1.H20}>{STAND_ALONE_RESULT_DELETE.TITLE}</TextH3>
      </Styled.TextWrapper>
      <TextP font={fonts.font1.H14}>
        {STAND_ALONE_RESULT_DELETE.SUB_TEXT.replace('##nameSingle##', hook.nameSingle).replace('##ID##', `${hook.deleteResultIds[0]?.id}`)}
      </TextP>
      <div>
        <Styled.TextWrapper>
          <TextP font={fonts.font1.H16B}>
            {STAND_ALONE_RESULT_DELETE.SUMMARY}
          </TextP>
          <Styled.ListStyled>
            <li>
              {hook.name}: {hook.deleteResultIds.length}
            </li>
          </Styled.ListStyled>
        </Styled.TextWrapper>
      </div>
      <div>
        <TextP font={fonts.font1.H16B}>
          {STAND_ALONE_RESULT_DELETE.OBJECTS}
        </TextP>
        {
          hook.deleteResultIds && (
            <Styled.ListStyled>
              {hook.deleteResultIds?.map((result) => (
                <li key={result.id}>
                  {hook.nameSingle}: {' '}
                  <LinkComponent url={`/${hook.baseUrl}}/${result.id}`}>
                    {result.title}
                  </LinkComponent>
                </li>
              ))}
            </Styled.ListStyled>
          )
        }
        <Styled.FlexBoxStyled>
          <Button
            onClick={() => hook.deleteResultsHandler(hook.deleteResultIds.map((result) => result.id))}
            theme="warning"
          >
            <TextP font={fonts.font1.H12}>
              {STAND_ALONE_RESULT_DELETE.CONFORM}
            </TextP>
          </Button>
          <Styled.ButtonWrapper>
            <LinkComponent
              url={hook.deleteResultIds[0]
                ? `/${hook.baseUrl}/${hook.deleteResultIds[0].id}/change`
                : `/${hook.baseUrl}`}
              theme="buttonSecondary"
            >
              <div>
                <TextP font={fonts.font1.H12}>
                  {STAND_ALONE_RESULT_DELETE.BACK.LABEL}

                </TextP>
              </div>
            </LinkComponent>
          </Styled.ButtonWrapper>
        </Styled.FlexBoxStyled>
      </div>
    </AppWrapper>
  );
};
