export const breachcrumbPath = {
  core: {
    name: 'core',
    url: '/core',
  },
  admin: {
    name: 'admin',
    url: '/admin',
  },
  hospitals: {
    name: 'hospitals',
    url: '/core/hospital',
  },
  projectInterest: {
    name: 'project interest',
    url: '/core/projectinterest',
  },
  university: {
    name: 'universities',
    url: '/core/university',
  },
  healthAuthority: {
    name: 'health authorities',
    url: '/core/healthauthority',
  },
  healthAuthorityRegion: {
    name: 'health authority regions',
    url: '/core/healthauthorityregion',
  },
  healthCategory: {
    name: 'health Categories',
    url: '/core/healthCategory',
  },
  project: {
    name: 'projects',
    url: '/core/project',
  },
  studyResult: {
    name: 'studyResult',
    url: '/core/studyResult',
  },
  ppoResult: {
    name: 'ppoResult',
    url: '/core/ppoResult',
  },
  standAloneResult: {
    name: 'standAloneResult',
    url: '/core/standAloneResult',
  },
  user: {
    name: 'user',
    url: '/core/user',
  },
  researcher: {
    name: 'researcher',
    url: '/core/researcher',
  },
  volunteer: {
    name: 'volunteer',
    url: '/core/volunteer',
  },
  adminUser: {
    name: 'Admin User',
    url: '/admin/adminUser',
  },
  changePassword: {
    name: 'Password change',
    url: '/passwordChange',
  },
  uploadCsv: {
    name: 'upload csv file',
    url: '/csvUploader',
  },
  downloadCsv: {
    name: 'Download csv file',
    url: '/cvsdownloader',
  },
};
