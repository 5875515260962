import React from 'react';
import { TextP } from '../Text';
import * as Styled from './styles';

type InputCheckboxProps = {
  label?: string
  postLabal?: string
  checkedValue: boolean
  handleChange: (checked: boolean) => void
  disabled?: boolean
  theme?: string
  error?: string
}

const InputCheckbox = ({
  label,
  checkedValue,
  postLabal,
  disabled,
  handleChange,
  theme,
  error,
}: InputCheckboxProps) => (
  <Styled.InputWrapper>
    {label && (
      <Styled.LabelStyled theme={theme}>
        <TextP>{label}</TextP>
      </Styled.LabelStyled>
    )}
    <div>
      <input
        type="checkbox"
        id={postLabal}
        checked={checkedValue}
        onChange={({ target: { checked } }) => {
          handleChange(checked);
        }}
        disabled={disabled}
      />
      {error && (
        <Styled.ErrorWrapper>
          <TextP>{error}</TextP>
        </Styled.ErrorWrapper>
      )}
    </div>
    {postLabal && (
      <Styled.PostLabelStyled>
        <label htmlFor={postLabal}>{postLabal}</label>
      </Styled.PostLabelStyled>
    )}
  </Styled.InputWrapper>
);

export default InputCheckbox;
