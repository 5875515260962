import React, { useEffect } from 'react';
import AppWrapper from '../../../components/AppWrapper';
import { breachcrumbPath } from '../../../utils/breadcrumbConst';
import { useStandAloneResult } from '../../../hooks';
import PageTitle from '../../../components/PageTitle';
import TableBasic from '../../../components/Tablebasic';
import { resultStatusFilterOptions, standAloneResultsColumns } from '../../../utils/const';
import SearchBar from '../../../components/SearchBar';
import FilterBar from '../../../components/FilterBar';

export const StandAloneResult = () => {
  const hook = useStandAloneResult();

  useEffect(() => {
    hook.getStandAloneResults(true);
  }, []);

  return (
    <AppWrapper
      breadcrumbs={[breachcrumbPath.core, breachcrumbPath.standAloneResult]}
    >
      <PageTitle
        title={`Select ${hook.nameSingle} to change`}
        linkUrl={`/${hook.baseUrl}/add`}
        buttonText={`add ${hook.nameSingle}`}
        useIcon
      />

      <SearchBar
        handleSearch={hook.handleSearch}
        setSearchValue={hook.setSearchFilter}
        searchValue={hook.searchFilter}
        searchCount={hook.filterStandAloneResults.length}
        total={hook.standAloneResults.length}
      />

      <FilterBar
        filterOptions={resultStatusFilterOptions}
        keyName="status"
        handleChange={hook.handleUpdateFilter}
        selectedValue={hook.resultStatusFilter}
      />

      <TableBasic
        data={hook.filterStandAloneResults || []}
        columns={standAloneResultsColumns}
        selectedList={hook.selectedStandAloneResults}
        handleCheck={hook.handleCheck}
        footerText={`${hook.filterStandAloneResults.length} ${hook.name}`}
        basicUrl={`/${hook.baseUrl}`}
      />

    </AppWrapper>
  );
};
